import React from 'react';

import { Box } from '@material-ui/core';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import { useTranslation } from '../../../hooks/useTranslation';
import LatestUpdatesSlider from '../../components/LatestUpdates/LatestUpdatesSlider';
import { standardLatestUpdates } from '../../components/LatestUpdates/data/standardLatestUpdates';

export default function FeaturedSection() {
    const t = useTranslation();
    return (
        <Box component="section" pb={[10, 12, 12, 16, 20]}>
            <SectionTitle
                title={t('home_page.featured.title')}
                subtitle={t('home_page.featured.subtitle')}
                icon="icon-star"
            />

            <LatestUpdatesSlider data={standardLatestUpdates} />
        </Box>
    );
}
