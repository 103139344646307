import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Dialog } from '@material-ui/core';
import { Box, Icon } from 'portal-modules/core-ui';

import { globalSel, globalOp } from '../../../store/global';
import { addStorageItem, getStorageItem, removeStorageItem } from '../../../helpers/localStorage';
import { validPath } from '../../../helpers/utils';
import { INTERSTITIAL_LOGOUT_MODAL } from '../../../constants/modals.constants';
import { getSessionToken } from '../../../bridge';
import { addCookie } from '../../../bridge/cookieStorage';
import { setGAEvent } from '../../../helpers/GA4Helper';
import { ModalStyled } from '../Modal/Modal.styled';
import { type AppRouteName } from '../../../AppRoutes';
import Content from './content';
import { getUnseenInterstitial } from './utils';

function Interstitial() {
    const dispatch = useDispatch();
    const location = useLocation();

    const pages = getStorageItem('pages');

    const isAuthenticated = getSessionToken();

    const isValidPath = validPath(window.location.pathname);
    const maintenanceMode = useSelector(globalSel.maintenanceModeSelector);
    const currentUser: number | false = useSelector(globalSel.isLoggedIn);
    const pathname = window.location.pathname === '/' ? 'home' : window.location.pathname.slice(1);
    const popups: Interstitial[] | undefined = useSelector(globalSel.popUpsSelector)?.[pathname];

    const ftueInformation = useSelector(globalSel.ftueSelector);

    const interstitialsState = useSelector(globalSel.interstitials);

    useEffect(() => {
        if (!currentUser) return;

        const intervalID = setInterval(() => {
            // @ts-expect-error
            dispatch(globalOp.getPopups()).then(() => {
                removeStorageItem('pages');
            });
        }, 30 * 60 * 1000);

        return () => {
            clearInterval(intervalID);
        };
    }, [currentUser, dispatch]);

    useEffect(() => {
        const viewedInterstitials = getStorageItem('interstitials');
        if (currentUser && popups?.length) {
            if (
                pathname === 'loyalty-point-shop' &&
                (!ftueInformation || !ftueInformation?.Claimed)
            ) {
                return undefined;
            }

            const interstitial = getUnseenInterstitial({
                viewedInterstitials: viewedInterstitials,
                popups,
                userID: currentUser,
            });

            if (interstitial) {
                dispatch(
                    globalOp.openInterstitials({
                        open: true,
                        screen: pathname,
                        data: interstitial,
                    }),
                );

                const viewedInterstitialsToSave =
                    viewedInterstitials && viewedInterstitials[currentUser]
                        ? [...viewedInterstitials[currentUser], interstitial.Id]
                        : [interstitial.Id];
                addStorageItem('interstitials', {
                    [currentUser]: viewedInterstitialsToSave,
                });
                addCookie('interstitials', 1, 12);
            }
        }
    }, [currentUser, dispatch, location, pathname, popups, pages, ftueInformation]);

    useEffect(() => {
        if (
            !isAuthenticated &&
            isValidPath &&
            !getStorageItem('authorizing') &&
            location.pathname !== '/'
        ) {
            dispatch(
                globalOp.handleOpenModal({
                    open: true,
                    variant: INTERSTITIAL_LOGOUT_MODAL,
                }),
            );
        }
    }, [currentUser, dispatch, isAuthenticated, isValidPath, pathname]);

    const handleClose = () => {
        dispatch(globalOp.closeInterstitials());
        setGAEvent('clickEvent', {
            screen_name: 'interstitial',
            screen_type: 'pop-up',
            interaction_object: 'close_button',
            interaction_object_category: 'close',
            interstitial_id: interstitialsState?.data?.Id,
        });
    };

    if (!popups || !popups.length || !currentUser) return null;

    return (
        interstitialsState.open &&
        !maintenanceMode && (
            <Dialog
                maxWidth="sm"
                open={interstitialsState.open}
                className="WP-Modal-dialog"
                onClose={handleClose}
            >
                <Box
                    component={ModalStyled}
                    className="WP-Modal-root"
                    width="100%"
                    position="relative"
                    flexDirection="column"
                >
                    <Box position="absolute" top={12} right={12} onClick={handleClose}>
                        <Box
                            component={Icon}
                            size={18}
                            className={clsx(
                                'icon-close',
                                'cursor-pointer',
                                'WP-Modal-close-button flex-center',
                            )}
                            borderRadius="50%"
                        />
                    </Box>
                    <Content data={interstitialsState.data} />
                </Box>
            </Dialog>
        )
    );
}

interface BaseInterstitial {
    Id: string;
    TriggerScreen: AppRouteName;
    StartDate: string;
    EndDate: string;
    Priority?: number;
    BackgroundImageUrl: string;
    HeaderImageUrl: string;
    CtaText: string;
    CtaLink: string;
    ParentId?: string;
    ImageUrl?: string;
    BodyText?: string;
}

interface StandardInterstitial extends BaseInterstitial {}

interface FlashOfferInterstitial extends BaseInterstitial {
    StoreListingId: string;
}

// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
type Interstitial = StandardInterstitial | FlashOfferInterstitial;

export default Interstitial;
export type { Interstitial, StandardInterstitial, FlashOfferInterstitial };
