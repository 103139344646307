export const MAILCHIMP_URL = process.env.REACT_APP_MAILCHIMP_URL;
export const MAILCHIMP_CHECKBOX_ID = process.env.MAILCHIMP_CHECKBOX_ID;
export const MAILCHIMP_CHECKBOX_NAME = process.env.MAILCHIMP_CHECKBOX_NAME;

export const API_URL = `${process.env.REACT_APP_API_URL}`;
export const APP_REDIRECT_URL = process.env.REACT_APP_REDIRECT_URI!;

export const SCOPELY_ID_PROVIDER_URL = process.env.REACT_APP_SCOPELY_ID_PROVIDER_URL;
export const SCOPELY_ID_AUTH_URL = process.env.REACT_APP_SCOPELY_ID_AUTH_URL;
export const SCOPELY_ID_CLIENT_ID = process.env.REACT_APP_SCOPELY_ID_CLIENT_ID;

export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;

export const API_ENV = process.env.REACT_APP_ENVIRONMENT;
export const TITAN_API_KEY = process.env.REACT_APP_TITAN_API_KEY;
export const XSOLLA_URL = process.env.REACT_APP_XSOLLA_URL;

export const BUGSANG_RELEASE_STAGE = process.env.REACT_APP_BUGSNAG_RELEASE_STAGE;

export const GRIDLY_API_KEY = process.env.REACT_APP_GRIDLY_API_KEY;
export const GRIDLY_VIEW_ID = process.env.REACT_APP_GRIDLY_VIEW_ID;
