export interface Anchor {
    text: string;
    anchor: string;
    icon?: string;
    customIcon?: string;
    screenCategory: string;
}

export const dailyOfferAnchor: Anchor = {
    text: 'daily.offers',
    anchor: 'WP-daily-offer',
    icon: 'icon-calendar',
    screenCategory: 'daily_offers',
};

export const featuredOffersAnchor: Anchor = {
    text: 'featured.offers',
    anchor: 'WP-featured',
    customIcon: 'feature-offers',
    screenCategory: 'featured_offers',
};

export const bonusRollsAnchor: Anchor = {
    text: 'bonus.rolls',
    anchor: 'WP-bonus-rolls',
    customIcon: 'bonus-roll-offers',
    screenCategory: 'bonus_rolls',
};

export const energyAnchor: Anchor = {
    text: 'energy',
    anchor: 'WP-energy',
    customIcon: 'energy-offers',
    screenCategory: 'energy',
};

export const stickerPacksAnchor: Anchor = {
    text: 'sticker.packs',
    anchor: 'WP-stickers',
    customIcon: 'sticker-offers',
    screenCategory: 'sticker_packs',
};

export const loyaltyPointsAnchor: Anchor = {
    text: 'loyalty.points',
    anchor: 'WP-loyalty-points',
    customIcon: 'loyalty-point-offers',
    screenCategory: 'loyalty_points',
};

export const dailyGiftAnchor: Anchor = {
    text: 'daily.gift',
    anchor: 'WP-gift',
    customIcon: 'daily-offers',
    screenCategory: 'daily_gift',
};

export const dailyDealsAnchor: Anchor = {
    text: 'daily.deals',
    anchor: 'WP-daily-deals',
    customIcon: 'daily-offers',
    screenCategory: 'daily_deals',
};

export const diceAndMoreAnchor: Anchor = {
    text: 'dice.and.more',
    anchor: 'WP-dice-more',
    customIcon: 'vanity-offers',
    screenCategory: 'dice_and_more',
};

export const stickersAndMoreAnchor: Anchor = {
    text: 'stickers.and.more',
    anchor: 'WP-stickers',
    customIcon: 'sticker-offers',
    screenCategory: 'stickers_and_more',
};
