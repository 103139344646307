import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';

import AddToHomeScreen from '../../components/AddToHomeScreen/AddToHomeScreen';
import PageHeader from '../../components/PageHeader/PageHeader';
import DailyOffersSection from '../../components/DailyOffers/DailyOffersSection/DailyOffersSection';
import FeaturedSliderWrapper from '../../components/FeaturedBanner/FeaturedSliderWrapper';
import BonusRolls from '../../components/BonusRolls/BonusRolls';
import Energy from '../../components/Energy/Energy';
import Gifts from '../../components/Gifts/Gifts';
import Stickers from '../../components/Stickers/Stickers';
import LoyaltyPoints from './LoyaltyPoints/LoyaltyPoints';
import { deviceType } from '../../../helpers/deviceType';

import { energyEnabledGetter } from '../../../core/application/config';
import { globalSel } from '../../../store/global';
import { dailyOffersSel } from '../../../store/dailyOffers';

import {
    dailyOfferAnchor,
    featuredOffersAnchor,
    bonusRollsAnchor,
    energyAnchor,
    dailyGiftAnchor,
    stickerPacksAnchor,
    loyaltyPointsAnchor,
    type Anchor,
} from '../../../constants/anchors.constants';
import { shopSel } from '../../../store/shop/selectors.js';

function Store() {
    const t = useTranslation();
    const authenticated = useSelector(globalSel.isAuthSelector);

    const theme = useSelector(globalSel.themeSelector);
    const customXS = useMediaQuery(theme.breakpoints.down(375), { noSsr: true });
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const breakpointsMac = useMediaQuery(theme.breakpoints.between(1780, 1800), {
        noSsr: true,
    });
    const dailyOffers = useSelector(dailyOffersSel.dailyOffers);
    const featuredWebOffers = useSelector(shopSel.WebFeatured);
    const giftItem = useSelector(shopSel.WebFreeDailyGift);
    const checkMobileOrTablet = deviceType();

    const [anchors, setAnchors] = useState<Anchor[]>([
        bonusRollsAnchor,
        stickerPacksAnchor,
        loyaltyPointsAnchor,
    ]);

    const fetchEnergyEnabled = async () => {
        const res = await energyEnabledGetter.execute();
        if (res) {
            setAnchors((prev) => prev.filter((anchor) => anchor.anchor !== 'WP-energy'));
            setAnchors((prev) =>
                prev.reduce<Anchor[]>(
                    (acc, anchor) =>
                        anchor.anchor === 'WP-bonus-rolls'
                            ? [...acc, anchor, energyAnchor]
                            : [...acc, anchor],
                    [],
                ),
            );
        }
    };

    useEffect(() => {
        fetchEnergyEnabled();
    }, []);

    useEffect(() => {
        setAnchors((prev) => prev.filter((anchor) => anchor.anchor !== 'WP-daily-offer'));
        if (dailyOffers) {
            setAnchors((prev) => [dailyOfferAnchor, ...prev]);
        }
    }, [dailyOffers]);

    useEffect(() => {
        setAnchors((prev) => prev.filter((anchor) => anchor.anchor !== 'WP-featured'));
        if (
            featuredWebOffers?.StoreFront?.Listings &&
            featuredWebOffers?.StoreFront?.Listings.length > 0
        ) {
            setAnchors((prev) =>
                prev.reduce<Anchor[]>(
                    (acc, anchor) =>
                        anchor.anchor === 'WP-bonus-rolls'
                            ? [...acc, featuredOffersAnchor, anchor]
                            : [...acc, anchor],
                    [],
                ),
            );
        }
    }, [featuredWebOffers?.StoreFront?.Listings]);

    useEffect(() => {
        setAnchors((prev) => prev.filter((anchor) => anchor.anchor !== 'WP-gift'));
        if (giftItem?.Listings && giftItem?.Listings[0]) {
            setAnchors((prev) =>
                prev.reduce<Anchor[]>(
                    (acc, anchor) =>
                        anchor.anchor === 'WP-stickers'
                            ? [...acc, dailyGiftAnchor, anchor]
                            : [...acc, anchor],
                    [],
                ),
            );
        }
    }, [giftItem?.Listings]);

    return (
        <Box className="WP-store-page" pt={[5, 5, 5, 7]}>
            {checkMobileOrTablet && <AddToHomeScreen />}

            <PageHeader
                title={t('store')}
                subtitle={t('store.page.sub.title')}
                desc={t('store.page.text')}
                icon="icon-store-outlined"
                anchors={anchors}
            />

            {authenticated && <DailyOffersSection />}

            <FeaturedSliderWrapper selector="WebFeatured" />

            <BonusRolls
                sectionSubtitle="bonus.rolls.subtitle"
                selector="WebBonusRolls"
                limit={breakpointsSM ? 6 : 4}
                limitSkeleton={[4, 6, 6]}
                imageSizeHeight={[customXS ? 60 : 50, 60, 60, breakpointsMac ? 90 : 60, 120]}
            />

            <Energy
                sectionSubtitle="energy.subtitle.store"
                selector="WebEnergySKUs"
                limit={breakpointsSM ? 3 : 2}
                limitSkeleton={[2, 2, 2]}
                cardGridSizeSm={4}
            />

            <Gifts title={t('daily.gift')} subtitle={t('daily.gift.text')} />

            <Stickers
                sectionTitle="sticker.packs"
                sectionSubtitle="sticker.packs.subtitle"
                selector="WebSticker"
                limit={breakpointsSM ? 6 : 4}
                limitSkeleton={[4, 6, 6]}
            />

            <Box id="store-loyalty" className="store-loyalty">
                <LoyaltyPoints
                    selector="WebLoyaltyPoint"
                    limit={breakpointsSM ? 6 : 4}
                    limitSkeleton={[4, 6, 6]}
                />
            </Box>
        </Box>
    );
}

export default Store;
