import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getStorageItem, removeStorageItem, setStorageItem } from './helpers/localStorage';
import globalSlice, { globalSel } from './store/global';
import { scopelyId } from './configs/scopelyId.config';
import {
    loginAnalyticsTracking,
    TRACKING_ACTIONS,
    TRACKING_ERROR_CODES,
    TRACKING_TYPES,
} from './helpers/loginAnalyticsTracking';
import { globalOp, loginAsync } from './store/global/operations';
import { getUrlParam } from './helpers/utils';

function Auth({ children }) {
    const history = useHistory();
    const dispatch = useDispatch();

    const scopelyLoginStatus = globalSlice.actions.setScopelyLogin;
    const logoutParam = getUrlParam('state');
    const authenticated = useSelector(globalSel.isAuthSelector);
    const app_version = useSelector(globalSel.versionSelector);

    const scopelyLogin = useCallback(async () => {
        let currentLocation = window.location.href;
        dispatch(scopelyLoginStatus(true));
        setStorageItem('loggingIn', true);
        const previousLocation = scopelyId.previousLocation();
        if (previousLocation) history.replace(previousLocation.pathname);
        let loginResult = null;
        try {
            loginResult = await scopelyId.authorizationResult(currentLocation);
        } catch (e) {
            loginAnalyticsTracking({
                type: TRACKING_TYPES.SCOPELY_ID,
                action: TRACKING_ACTIONS.LOGIN_FAILED,
                errorCode: `${TRACKING_ERROR_CODES.SCOPELY_AUTH_RESULT_CATCH}${
                    e?.error?.code || TRACKING_ERROR_CODES.UNKNOWN_ERROR
                }`,
            });
            removeStorageItem('loggingIn');
            removeStorageItem('authorizing');
            dispatch(scopelyLoginStatus(false));
        }

        if (loginResult) {
            if (loginResult.isSuccessful()) {
                const scopelyToken = loginResult.getResponse().getAccessToken();
                setStorageItem('accessToken', scopelyToken);
                dispatch(loginAsync()).then(() => {
                    removeStorageItem('loggingIn');
                    dispatch(scopelyLoginStatus(false));
                    removeStorageItem('authorizing');
                });
            } else {
                loginAnalyticsTracking({
                    type: TRACKING_TYPES.SCOPELY_ID,
                    action: TRACKING_ACTIONS.LOGIN_FAILED,
                    errorCode: `${TRACKING_ERROR_CODES.SCOPELY_IS_NOT_SUCCESSFUL}${TRACKING_ERROR_CODES.UNKNOWN_ERROR}`,
                });
                removeStorageItem('loggingIn');
                removeStorageItem('authorizing');
                dispatch(scopelyLoginStatus(false));
            }
        }
    }, [dispatch, history, scopelyLoginStatus]);

    const scopelyLogout = useCallback(async () => {
        let currentLocation = window.location.href;
        if (scopelyId.isLogoutUri(currentLocation)) {
            const previousLocation = scopelyId.previousLocation();
            if (previousLocation) history.replace(previousLocation.pathname);
            dispatch(globalOp.logout());
            let logoutResult = await scopelyId.logoutResult(currentLocation);

            if (logoutResult.isSuccessful()) {
                // eslint-disable-next-line no-console
                console.log('logout success');
            } else {
                // eslint-disable-next-line no-console
                console.log(logoutResult.getError());
            }
            removeStorageItem('authorizing');
        }
    }, [history, dispatch]);

    useEffect(() => {
        const loginInProgress = getStorageItem('loggingIn');

        if (app_version && !loginInProgress) {
            let currentLocation = window.location.href;
            if (!authenticated) {
                if (scopelyId.isAuthorizationUri(currentLocation)) scopelyLogin();
                else {
                    if (getStorageItem('authorizing')) {
                        loginAnalyticsTracking({
                            type:
                                getStorageItem('loginProcessing') === 'withFacebook'
                                    ? TRACKING_TYPES.FACEBOOK
                                    : TRACKING_TYPES.SCOPELY_ID,
                            action: TRACKING_ACTIONS.LOGIN_ABANDONED,
                        });
                    }

                    removeStorageItem('authorizing');
                }
            } else {
                if (scopelyId.isLogoutUri(currentLocation) && logoutParam === 'scopely-logout')
                    scopelyLogout();
                else {
                    dispatch(globalOp.getConfigs()).then(() => {
                        dispatch(globalOp.getUserData());
                    });
                }
            }
        }
    }, [authenticated, dispatch, app_version, scopelyLogin, scopelyLogout, logoutParam]);

    return children;
}

export default Auth;
