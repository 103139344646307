import React from 'react';

import { Box } from '@material-ui/core';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import { useTranslation } from '../../../hooks/useTranslation';
import DailyRewardBanner from '../../components/DailyReward/DailyRewardBanner';
import DailyLoginCalendarModal from '../../components/DailyReward/DailyCalendarModal/DailyLoginCalendarModal';

export default function HeaderSection() {
    const t = useTranslation();
    return (
        <Box component="section" mt={[5, 6, 6, 8, 10]} pb={[10, 12, 12, 16, 20]}>
            <SectionTitle title={t('welcome')} />

            <DailyRewardBanner />
            <DailyLoginCalendarModal />
        </Box>
    );
}
