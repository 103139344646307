import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import moment from 'moment';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';

import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';

import { CountdownUTCStyled } from './CountdownUTC.styled';
import timerImage from '../../../assets/images/Global/timer.svg';

const CountdownUTC = ({ start, interval = 1000, d, h, m, s, callback, text }) => {
    const theme = useTheme();

    const t = useTranslation();

    if (!start) return null;

    let eventTime = moment.utc(start).valueOf();

    const renderer = ({ days, formatted }) => {
        if (!d && days > 0) {
            formatted['hours'] = (Number(formatted['hours']) + days * 24).toString();
        }

        return (
            <Box
                component={Typography}
                className="white-space-nowrap"
                variant={['p12', 'p12', 'p12', 'p14', 'p16']}
                fWeight="bold"
            >
                {d && !!days && (
                    <span>
                        {formatted['days']}
                        {t('d')}{' '}
                    </span>
                )}
                {h && (
                    <span>
                        {formatted['hours']}
                        {t('h')}
                    </span>
                )}
                {m && (
                    <span>
                        {' '}
                        {formatted['minutes']}
                        {t('m')}
                    </span>
                )}
                {s && (
                    <span>
                        {' '}
                        {formatted['seconds']}
                        {t('s')}
                    </span>
                )}
            </Box>
        );
    };

    return (
        <Box
            component={CountdownUTCStyled}
            className="WP-countdown"
            display="flex"
            alignItems="center"
            position="relative"
            borderRadius={80}
            bgcolor={theme.hexToRgba(theme.palette.common.black, 0.4)}
            p={1}
            minHeight={[22, 26, 26, 32, 36]}
        >
            <Box component="img" src={timerImage} alt="Timer" height="100%" aria-hidden />
            <Box
                className="WP-timer-text"
                color={theme.palette.common.white}
                px={2}
                display="flex"
                alignItems="center"
            >
                <Box
                    component={Typography}
                    variant={['p12', 'p12', 'p10', 'p14', 'p16']}
                    fWeight="medium"
                    pr={1}
                    color={theme.hexToRgba(theme.palette.common.white, 0.8)}
                >
                    {text}
                </Box>
                <Countdown
                    date={eventTime}
                    renderer={renderer}
                    intervalDelay={interval}
                    onComplete={callback}
                />
            </Box>
        </Box>
    );
};

CountdownUTC.propTypes = {
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
    interval: PropTypes.number,
    format: PropTypes.array,
    callback: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    d: PropTypes.bool,
    h: PropTypes.bool,
    m: PropTypes.bool,
    s: PropTypes.bool,
    text: PropTypes.string,
};

export default CountdownUTC;
