import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../hooks/useTranslation';
import PropTypes from 'prop-types';

import { Box, Icon, Typography } from 'portal-modules/core-ui';

import { globalSel } from '../../../store/global';
import CountdownUTC from '../CountdownUTC/CountdownUTC';
import IconCustom from '../IconCustom/IconCustom';

const SectionTitle = ({
    title,
    subtitle,
    icon,
    customIcon,
    isTimingLabelExist,
    time,
    callback,
}) => {
    const theme = useSelector(globalSel.themeSelector);
    const t = useTranslation();

    return (
        <Box mb={[4, 5, 5, 5, 6]}>
            {title && (
                <Box
                    variant={['h6', 'h6', 'h6', 'h5', 'h4']}
                    fWeight="bold"
                    className="WP-section-title"
                    color={theme.palette.brown.main}
                    component={Typography}
                    display="flex"
                    alignItems="center"
                >
                    {icon && <Box component={Icon} className={icon} size={20} mr={2} />}
                    {customIcon && <IconCustom icon={customIcon} size={26} className="WP-mr-8" />}
                    {title}
                </Box>
            )}

            {subtitle && (
                <Box
                    className="text-truncate-second-line"
                    variant="p16"
                    fWeight="medium"
                    color={theme.palette.brown.main}
                    component={Typography}
                    mt={1}
                >
                    {subtitle}
                </Box>
            )}

            {isTimingLabelExist && time ? (
                <Box mt={3} display="flex">
                    <CountdownUTC
                        text={t('offers.refresh.timer')}
                        start={time}
                        interval={60 * 1000}
                        h
                        m
                        callback={callback}
                    />
                </Box>
            ) : null}
        </Box>
    );
};

SectionTitle.defaultProps = {
    isTimingLabelExist: false,
};

SectionTitle.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    subtitle: PropTypes.string,
    icon: PropTypes.string,
    customIcon: PropTypes.string,
    isTimingLabelExist: PropTypes.bool,
    time: PropTypes.string,
    callback: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    id: PropTypes.string,
    dailyDealsRef: PropTypes.object,
};

export default SectionTitle;
