import React, { useEffect, useState } from 'react';

import { ButtonStyled } from './Button.styled';
import { Icon } from 'portal-modules/core-ui';
import LoadAnimateButton from '../../LoadAnimateButton/LoadAnimateButton';
import IconCustom from '../../IconCustom/IconCustom';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    size?: 'large' | 'medium' | 'small' | 'extra-small';
    variant?:
        | 'primary'
        | 'secondary'
        | 'solid'
        | 'grey-1'
        | 'grey-2'
        | 'grey-3'
        | 'grey-4'
        | 'facebook-btn'
        | 'outlined'
        | 'brown'
        | 'outlined-800';
    completed?: boolean;
    fullWidth?: boolean;
    icon?: string;
    customIcon?: string;
    /**
     * is discount button with line through
     */
    hasDiscount?: boolean;
    isLoading?: boolean;
    preventLoading?: boolean;
    unAvailable?: boolean;
    propogate?: boolean;
    shine?: boolean;
};

export const Button = ({
    size = 'medium',
    variant = 'primary',
    completed = false,
    fullWidth = true,
    icon = '',
    customIcon = '',
    hasDiscount = false,
    isLoading = false,
    preventLoading = false,
    unAvailable = false,
    propogate = false,
    shine = false,
    ...rest
}: ButtonProps) => {
    const { className, disabled, onClick, children, ...spanProps } = rest;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isLoading) setLoading(isLoading);
        return () => {
            setLoading(false);
        };
    }, [setLoading, isLoading]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        !propogate && event.stopPropagation();
        if (!onClick) return false;
        if (!preventLoading) setLoading(true);

        // TODO: avoid setState inside onClick. Now we simply keep it
        // @ts-expect-error
        onClick(setLoading);
    };

    return (
        <ButtonStyled
            fullWidth={fullWidth}
            className={`${shine && 'shine'} ${
                completed || unAvailable ? 'completed' : variant
            } ${size} ${className} 'cursor-pointer'`}
            disabled={disabled || unAvailable}
            onClick={!loading && !completed && !unAvailable ? handleClick : undefined}
        >
            {loading ? (
                <LoadAnimateButton hasText bodySize="small" />
            ) : (
                <>
                    {icon && !loading && <Icon className={`${icon} button-icon`} />}
                    {customIcon && (
                        <IconCustom
                            icon={customIcon}
                            className="WP-mr-8 button-icon custom-button-icon"
                        />
                    )}

                    <span
                        className={`button-text ${hasDiscount ? 'discount-text' : ''}`}
                        {...spanProps}
                    >
                        {children}
                    </span>
                </>
            )}
        </ButtonStyled>
    );
};
