import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getStorageItem } from './helpers/localStorage';
import globalSlice from './store/global';
import { getSessionToken } from './bridge';
import { LANGUAGES } from './constants/languages.constantants';

interface GlobalStoreLoaderProps {
    children: React.ReactNode;
}

function GlobalStoreLoader({ children }: GlobalStoreLoaderProps) {
    const dispatch = useDispatch();
    const [initializedGlobalStore, setInitializedGlobalStore] = useState(false);

    useEffect(() => {
        dispatch(globalSlice.actions.setLanguage(getStorageItem('language') || LANGUAGES[0]));
        dispatch(globalSlice.actions.setAuthenticated(!!getSessionToken()));
        dispatch(globalSlice.actions.setProfileLoading(!!getSessionToken()));
        dispatch(globalSlice.actions.setFacebookData(getStorageItem('fbData')));

        setInitializedGlobalStore(true);
    }, []);

    if (!initializedGlobalStore) return null;

    return <>{children}</>;
}

export default GlobalStoreLoader;
