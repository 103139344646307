import React, { lazy, Suspense } from 'react';
import { useFeatureFlag } from '../../../helpers/featureFlag';
import { FF_FLASH_OFFERS } from '../../../constants/featureFlag.constants';
import { type FlashOfferInterstitial, type Interstitial } from './Interstitial';

const StandardContent = lazy(() => import('./standard-content/standard-content'));
const FlashOfferContent = lazy(() => import('./flash-offer-content/flash-offer-content'));

interface ContentProps {
    data: Interstitial;
}

function Content({ data }: ContentProps) {
    const isFFFlashOffersEnabled = useFeatureFlag(FF_FLASH_OFFERS);

    return (
        <Suspense fallback={null}>
            {isFFFlashOffersEnabled ? (
                <>
                    {isFlashOffer(data) ? (
                        <FlashOfferContent data={data} />
                    ) : (
                        <StandardContent data={data} />
                    )}
                </>
            ) : (
                <StandardContent data={data} />
            )}
        </Suspense>
    );
}

function isFlashOffer(data: Interstitial): data is FlashOfferInterstitial {
    return (
        'StoreListingId' in data &&
        typeof data.StoreListingId === 'string' &&
        Boolean(data.StoreListingId)
    );
}

export default Content;
