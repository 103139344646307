import React, { Suspense } from 'react';
import PageLoader from './views/components/PageLoader/PageLoader';
import { Route, Switch } from 'react-router-dom';
import { APP_ROUTES } from './AppRoutes';
import NotFoundPage from './views/pages/NotFound/NotFound';
import Interstitial from './views/components/Interstitial/Interstitial';
import { useSelector } from 'react-redux';
import { globalSel } from './store/global';
import Layout from './views/layout/Layout';

const App = () => {
    const flagedPages = useSelector(globalSel.pageFlags);
    return (
        <Layout>
            <Suspense fallback={<PageLoader />}>
                <Switch>
                    {APP_ROUTES.map((route) => {
                        const isHidden =
                            route.name &&
                            flagedPages?.[route.name.toLowerCase()]?.Enabled === false;
                        return (
                            !isHidden && (
                                <Route key={route.path} path={route.path} exact={route.exact}>
                                    <route.component />
                                </Route>
                            )
                        );
                    })}
                    <Route component={NotFoundPage} />
                </Switch>
            </Suspense>
            <Interstitial />
        </Layout>
    );
};

export default App;
