import { reducers } from './reducers';
import { configureStore } from '@reduxjs/toolkit';
import ReduxThunk from 'redux-thunk';

import { IS_DEVELOPMENT } from '../constants';

const rootReducer = reducers();

export function setupStore(preloadedState = {}) {
    const middleware = [ReduxThunk];
    const store = configureStore({
        reducer: rootReducer,
        middleware,
        devTools: IS_DEVELOPMENT,
        enhancers: [],
        preloadedState,
    });
    return store;
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
