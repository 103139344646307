import { type Interstitial } from './Interstitial';

interface ViewedInterstitialsData {
    [userID: string]: string[];
}

type ViewedInterstitials = ViewedInterstitialsData | null;

function getUnseenInterstitial({
    viewedInterstitials,
    popups,
    userID,
}: {
    viewedInterstitials: ViewedInterstitials;
    popups: Interstitial[];
    userID: number;
}) {
    if (!viewedInterstitials || !viewedInterstitials[userID]) return popups[0];
    return popups.find((popup) => !viewedInterstitials[userID].includes(popup.Id));
}

export { getUnseenInterstitial };
